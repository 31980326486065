<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="white"
    flat
    height="75"
  >
    <v-btn
  class="d-sm-none"
  elevation="0"
  fab
  small
  @click="setDrawer(!drawer)"
>
  <v-icon v-if="value">
    mdi-view-quilt
  </v-icon>

  <v-icon v-else>
    mdi-menu
  </v-icon>
</v-btn>

    <div>
      <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="'Hello, '+ $store.getters.loggedInUserDetails.email"
      to="/"
    />
    <div>{{'Department: '+ $store.getters.loggedInUserDetails.department}}</div>
    </div>

    
<v-spacer />

    <!-- <v-btn color="primary" to="/" outlined class="mr-3"> <v-icon left>
        mdi-home
      </v-icon>Home</v-btn>
    <v-btn color="primary"  outlined @click="getNewTask">
    <v-icon left>
        mdi-message-plus-outline
      </v-icon>
    Get New Task</v-btn> -->

<v-spacer />
  

    <div class="mx-3" />
    <!-- <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-badge
            color="red"
            overlap
            bordered
          >
            <template v-slot:badge>
              <span>1</span>
            </template>

            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list
        :tile="false"
        nav
      >
        <div>
          <app-bar-item
            v-for="(n, i) in notifications"
            :key="`item-${i}`"
          >
            <v-list-item-title v-text="n" />
          </app-bar-item>
        </div>
      </v-list>
    </v-menu> -->

     <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
        
         <v-icon>mdi-account</v-icon>
         {{$store.getters.loggedInUserDetails.name}}
        </v-btn>
      </template>

      <v-list
        :tile="false"
        nav
      >
        <div>
          <app-bar-item
            to="/change-password"
          >
            <v-list-item-title v-text="'Change Password'" />
          </app-bar-item>
         
        </div>
        <div @click="logout">
            <app-bar-item
            
          >
            <v-list-item-title v-text="'Logout'" />
          </app-bar-item>
        </div>
      </v-list>
    </v-menu>

    <!-- <v-btn
      class="ml-2"
      min-width="0"
      text
      to="/pages/user"
    >
      <v-icon>mdi-account</v-icon>
    </v-btn> -->
  </v-app-bar>
</template>

<script>
  // Components
  import { VHover, VListItem } from 'vuetify/lib'

  // Utilities
  import { mapState, mapMutations } from 'vuex'

  export default {
    name: 'DashboardCoreAppBar',


    components: {
      AppBarItem: {
        render (h) {
          return h(VHover, {
            scopedSlots: {
              default: ({ hover }) => {
                return h(VListItem, {
                  attrs: this.$attrs,
                  class: {
                    'black--text': !hover,
                    'white--text secondary elevation-12': hover,
                  },
                  props: {
                    activeClass: '',
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                }, this.$slots.default)
              },
            },
          })
        },
      },
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      notifications: [
        'You have a new message',
      
      ],
    }),

    computed: {
      ...mapState(['drawer']),
    },

    methods: {
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),

        getNewTask(){
        this.$MessageApi.getNewTask().then(data=>{
          console.log(data.data);
          this.$router.push({path:'/message/'+data.data.data.conversationId, query:{messageActivity: data.data.data.messageActivity}},);
        }).catch(error=>{
          alert("No Task Found");
          console.log(error);
        })
      },
      logout(){
        this.$store.commit("doLogout");
        this.$router.push({path:'/auth/sign-in'});
      }
    },
  }
</script>
